<template>
    <div style="background-color: #EEEFF2;padding: 3rem 0;margin-bottom: 2rem;">
        <div class="container-fluid text-center px-lg-4 custom-wrapper success_box empty-box">
            <div class="success-checkmark">
                <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                </div>
            </div>
            <h4 class="text-center text-success mb-3">{{ $t('success_msg') }}!</h4>

            <div class="cart-summery">
                <div class="summery-padding">
                    <div class="cart-summery-item">
                        <p class="summery-title">{{ $t('order.cost') }}</p>
                        <p class="summery-total">{{ response.amount }} {{ currency }}</p>
                    </div>

                    <div class="cart-summery-item">
                        <p class="summery-title">{{ $t('order.delivery') }}</p>
                        <p class="summery-total">{{ response.delivery }} {{ currency }}</p>
                    </div>

                    <div class="cart-summery-item">
                        <p class="summery-title">{{ $t('order.tax') }}</p>
                        <p class="summery-total">{{ response.tax }} {{ currency }}</p>
                    </div>

                    <div class="cart-summery-item">
                        <p class="summery-title">{{ $t('discount') }}</p>
                        <p class="summery-total">{{ response.discount_amount }} {{ currency }}</p>
                    </div>

                    <hr>

                    <div class="text-center">
                        <p class="summery-title">{{ $t('order.total') }}</p>
                        <p class="summery-total">{{ response.total }} {{ currency }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Success',
    props: ["response","currency"],

}
</script>

<style>

.success_box {
    max-width: 600px;
    padding-top: 5rem;
    padding-bottom: 4rem;
}

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
}

.success-checkmark .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;
}

.success-checkmark .check-icon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
    content: "";
    height: 100px;
    position: absolute;
    background: #FFFFFF;
    transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(76, 175, 80, 0.5);
}

.success-checkmark .check-icon .icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #FFFFFF;
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
</style>