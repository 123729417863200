<template>
    <BaseLayout>
        <template #page-content>
            <BreadCrumb current-page="سلة الطلبات"/>
            <transition name="component-fade" mode="out-in">
                <div class="container-fluid" v-if="cartProducts.length">
                    <div class="cart-wrapper  mr-custom ml-custom">
                        <div class="table-responsive position-relative">
                            <table class="table  w-custom table-hover orders-table">
                                <thead>
                                <tr>
                                    <th>{{ $t('order.product') }}</th>
                                    <th>{{ $t('order.quantity') }}</th>
                                    <th>{{ $t('order.total') }}</th>
                                    <th>{{ $t('order.delete') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="{Product,size_price,Media,quantity,id} in cartProducts" :key="id">
                                    <td>
                                        <router-link :to="{ name: 'Product', params: { slug: Product.id }}">
                                            <div class="product">
                                                <img :src="APP_URL + '/' + Media.file" alt="" class="prod_img">
                                                <p class="product-name">
                                                    {{ Product.name }}
                                                </p>
                                            </div>
                                        </router-link>

                                    </td>
                                    <td>
                                        <div class="quantity-control">
                                            <button class="btn btn-gray btn-counter rounded-circle" type="button"
                                                    @click="decreaseCounter(id)" :disabled="quantity === 1">
                                                <span class="sr-only">decrease counter by 1</span>
                                                <i class="fas fa-minus"></i>
                                            </button>
                                            <input type="number" :value="quantity" min="1" class="form-control"
                                                   id="quantity">
                                            <button class="btn btn-gray btn-counter rounded-circle" type="button"
                                                    @click="increaseCounter(id)">
                                                <span class="sr-only">increase counter by 1</span>
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        {{ Math.round(size_price.price * quantity) }} {{ currency.coin.name }}
                                    </td>
                                    <td>
                                        <button class="btn btn-edit border rounded-circle btn-delete" @click="cart_id=id"
                                                type="button" data-toggle="modal" data-target="#confirmModal">
                                            <i class="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="cart-summery">
                            <div class="summery-padding">
                                <div class="cart-summery-item">
                                    <p class="summery-title">{{ $t('order.cost') }}</p>
                                    <p class="summery-total">{{ cost.amount }} {{ currency.coin.name }}</p>
                                </div>

                                <div class="cart-summery-item">
                                    <p class="summery-title">{{ $t('order.delivery') }}</p>
                                    <p class="summery-total">{{ cost.delivery }} {{ currency.coin.name }}</p>
                                </div>


                                <div class="cart-summery-item">
                                    <p class="summery-title">{{ $t('order.tax') }}</p>
                                    <p class="summery-total">{{ cost.tax }} {{ currency.coin.name }}</p>
                                </div>
                            </div>

                            <div class="cart-summery-item flex-wrap cart-summery--total">
                                <div>
                                    <p class="summery-title">{{ $t('order.promoCode') }}</p>
                                    <input type="text" placeholder="" v-model="promocode" class="btn-gray"/>
                                </div>
                                <button class="btn btn-warning font-weight-bold p-2" @click="checkPromoCode" style="font-size: .75rem">استخدام القسيمة</button>

                            </div>
                            <div class="cart-summery-item cart-summery--total">
                                <p class="summery-title">{{ $t('order.total') }}</p>
                                <div>
                                    <p class="summery-total mx-2" :class="{'line-through' : discount.show}">{{ cost.total }} {{ currency.coin.name }}</p>
                                    <p class="summery-total" v-if="discount.show">{{ discount.show ? discount.total : cost.total }} {{ currency.coin.name }}</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="!user_address">
                            <button type="button" class="btn btn-save btn-save-order" data-toggle="modal" data-target="#address_modal">
                                <span>   {{ $t('order.confirm') }} </span>
                            </button>
                        </div>

                        <button v-else type="button" class="btn btn-save btn-save-order" @click="handleOrder" :disabled="loading">
                            <span v-if="!loading">   {{ $t('order.confirm') }} </span>
                            <span class="loading" v-else>{{ $t('loading') }}</span>
                        </button>

                        <ConfirmDialog :status="deleting" @handleDelete="deleteItem(cart_id)"/>


                    </div>
                </div>
                <div v-else-if="done">
                    <SuccessCheck :response="response" :currency="currency.coin.name"/>
                </div>
                <EmptyCart v-else/>
            </transition>
            <div class="modal fade" id="address_modal" tabindex="-1" aria-labelledby="address_modalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">

                    <div class="modal-content">
                        <div class="modal-header flex-row-reverse">
                            <button type="button" class="close mx-0"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="justify-content-center">
                                <h5 class="modal-title" id="address_modalLabel">{{ $t('settings.address') }} </h5>
                            </div>
                        </div>
                        <div class="modal-body">
                            <transition name="component-fade" mode="out-in">
                                <div class="address-container" v-if="hideAddLocation">
                                        <AddressBox v-for="user_address in address" :key="user_address.id" :address="user_address"
                                                    @getUserAddress="getUserAddress" :preventDelete="true"
                                                    :isChecked="selectedAddress" @getSelectedAddress="getSelectedAddress"/>

                                            <button class="btn w-100 btn-success mb-2" v-if="address.length" data-dismiss="modal" aria-label="Close" :disabled="!selectedAddress">
                                                {{ $t('settings.save-changes') }}
                                            </button>

                                        <button type="button" class="btn w-100 btn-save mt-0" @click="toggleSection">

                                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="14.584" height="18.09"
                                                 viewBox="0 0 14.584 18.09">
                                                <path id="pin-add"
                                                      d="M10.825,18.625l-.533.465-.533-.465C5.278,14.712,3,11.3,3,8.292A7.184,7.184,0,0,1,10.292,1a7.184,7.184,0,0,1,7.292,7.292C17.584,11.3,15.306,14.712,10.825,18.625ZM4.62,8.292c0,2.309,1.869,5.213,5.672,8.64,3.8-3.427,5.672-6.331,5.672-8.64A5.567,5.567,0,0,0,10.292,2.62,5.567,5.567,0,0,0,4.62,8.292Zm4.861-.81V5.051H11.1V7.482h2.431V9.1H11.1v2.431H9.482V9.1H7.051V7.482Z"
                                                      transform="translate(-3 -1)" fill="#fff" fill-rule="evenodd"/>
                                            </svg>

                                            {{ $t('settings.add-location') }}
                                        </button>

                                </div>


                                <Add_address v-else @handleToggle="toggleSection"/>

                            </transition>

                        </div>
                    </div>
                </div>
            </div>

        </template>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/containers/BaseLayout";
import BreadCrumb from "@/components/BreadCrumb";
import EmptyCart from "@/views/Cart/components/EmptyCart";
import {mapGetters} from "vuex";
import {removeItem} from "@/services/cart";
import ConfirmDialog from "@/components/ConfirmDialog";
import store from "@/store"
import {useToast} from "vue-toastification";
import {storeOrder} from "@/services/orders";
import SuccessCheck from "@/views/Cart/components/SuccessCheck";
import {getAddresses, showAddress} from "../../services/address";

import AddressBox from "@/views/Settings/components/AddressBox";
import Add_address from "@/views/Settings/components/AddAddress";
import {checkPromoCode} from "../../services/cart";
export default {
    name: 'Cart',
    components: {BreadCrumb, BaseLayout, EmptyCart, ConfirmDialog,SuccessCheck  ,Add_address, AddressBox,},
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
        return {
            toast: useToast(),
            quantity: 1,
            address:[],
            cart_id: null,
            hideAddLocation:true,
            deleting: false,
            done:false,
            loading:false,
            promocode:'',
            response: null,
            selectedAddress: null,
            user_address:null,
            discount:{show:false,total:0}

        }
    },
    mounted() {
    this.getUserAddress()
        },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        decreaseCounter(id) {
            store.dispatch('handleDecrement', id)
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        increaseCounter(id) {
            store.dispatch('handleIncrement', id)
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        deleteItem(id) {
            this.deleting = true;
            removeItem({cart_id: id}).then(() => {
                this.deleting = false;
                this.cart_id = null;
                document.getElementById('hideModalConfirm').click();
                store.dispatch('deleteFromCart', id)
            })
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        handleOrder() {
            let address = store.getters.user_address;

            if (address) {
                const data = {
                    address_id: address.id,
                    ...address,
                    promocode: this.promocode,
                    status: '1',
                }

                this.loading = true

                storeOrder(data).then((res) => {
                    this.loading = false
                    this.done = true
                    if(res.data.status.code === 422) {
                        this.toast.error(res.data.status.message[0] || this.$t('error_msg'))
                    }else {
                        this.response = res.data.Order
                        store.dispatch('resetCart')
                        setTimeout(()=>this.done = false,15000)
                    }

                }).catch(()=> {
                    this.loading = false;
                    this.toast.error(this.$t('error_msg'))
                })
            } else {
                // this.$router.push('/settings')
                this.toast.error('الرجاء تحديد العنوان قبل تأكيد الطلب, من قائمة الإعدادات')
            }
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getUserAddress() {
            getAddresses().then(res => {
                this.address = res.data.Addresses
            })
        },
        toggleSection() {
            this.hideAddLocation = !this.hideAddLocation;
            this.getUserAddress();
        },

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        getSelectedAddress(id) {
            let prevVal = this.selectedAddress;
            this.selectedAddress = prevVal === id ? null : id
            showAddress({address_id: id})
                .then(res => {
                    prevVal === id ?
                        store.dispatch('fetchAddress', null) :
                        store.dispatch('fetchAddress', res.data.Address)
                        this.user_address = store.getters.user_address

                })
        },

        checkPromoCode() {
            checkPromoCode({promocode: this.promocode})
            .then(res=> {
                const {status,Discount} = res.data
                if(status.code === 200) {
                    if(status.status === 'fail') {
                        this.toast.error(status.message[0])
                    }else {
                        let discount = this.cost.amount * (Discount.rate/100);
                        if(discount > Discount.limit){
                            discount = Discount.limit
                        }
                        this.discount.show = true
                        this.discount.total = (this.cost.amount + this.cost.tax + this.cost.delivery) - discount
                    }
                }else {
                    this.toast.error(status.message[0])
                }
            }).catch(e=>console.log(e))
        }

    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            cartProducts: "cartProducts"
        }),

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        APP_URL: function () {
            return process.env.VUE_APP_REMOTE_URL
        },
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        currency: function () {
            return store.getters.getCurrency;
        },

        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        cost : function () {
            let final_cost = {
                delivery:0,
                tax:0,
                amount:0,
                total:0
            };
            this.cartProducts.forEach(product=> {
                final_cost.delivery += product.delivery;
                final_cost.tax += product.tax;
                final_cost.amount += Math.round(product.size_price.price * product.quantity);
                final_cost.total += product.delivery + product.tax + Math.round(product.size_price.price * product.quantity);
            })
            return final_cost
        }

    },
}
</script>


<style scoped>
.line-through {
    text-decoration: line-through;
    color: #ab2e2e;
}

.btn-gray:hover {
    background-color: var(--secondary-color);
}
</style>